import React, { useState } from 'react';
import './mtt-card.scss';

import { Link } from "gatsby";
import { GatsbyImage } from "gatsby-plugin-image";
import CustomMarkdown from "../../utils/customMarkdown";
import maleImage from "../../assets/TH_male.png";
import femalePng from "../../assets/TH_female.png";


const MttCard = ({ Image, Alt, Author, Gender, Description }) => {

    const [toggled, setToggled] = useState(false);
    const toggleImage = () => setToggled(!toggled);

    return (

        <div className="card-container">

            <div className="card">
                <div className="card__inner">

                    <div className="card__content">
                        {
                            !Image ?

                                Gender === "male" ? <img src={maleImage}></img> : <img src={femalePng} />
                                :

                                <GatsbyImage image={Image?.localFile.childImageSharp.gatsbyImageData} alt={Alt}
                                    style={{ maxHeight: "300px" }}
                                ></GatsbyImage>
                        }

                    </div>

                    <div className="card__content">
                        {
                            toggled ?
                                <div className="animations">
                                    {
                                        !Image ?

                                            Gender === "male" ?
                                                <img src={maleImage} className={toggled == true ? "blurred-img" : "test-image-blured"}></img>
                                                :
                                                <img src={femalePng} className={toggled == true ? "blurred-img" : "test-image-blured"} />
                                            :

                                            <GatsbyImage image={Image?.localFile.childImageSharp.gatsbyImageData} alt={Alt}
                                                className={toggled ? "blurred-img" : "test-image-blured"}
                                                style={{ maxHeight: "299px" }}></GatsbyImage>
                                    }
                                    <p className="description-author">{Description}</p>
                                </div>
                                : ""
                        }

                    </div>

                </div>
                <p id="author">{Author}</p>
                <button onClick={toggleImage} className="staff-info-btn">find out more..</button>
            </div>

        </div>




    )
}

export default MttCard;