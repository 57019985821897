import React, { useState, useRef, useEffect } from "react"
import { useStaticQuery, graphql } from "gatsby"
//import UIkit from "uikit"
import 'uikit/dist/css/uikit.css'
import Layout from "../layouts"

import "../styles/meet-the-team.scss";

import Header from "../components/header/header"

import MttCard from "../components/mtt-card/mtt-card"




// markup
const MeetTheTeam = () => {
    const data = useStaticQuery(graphql`
      {
        strapiMeetTheTeam {
            PageComponents
            Seo {
              metaDescription
              metaTitle
            }
        }

        allStrapiEmployeesCategories {
            edges {
              node {
                Title
              }
            }
          }

        allStrapiEmployees {
            edges {
              node {
                Alt
                Author
                Gender
                Image {
                    localFile{
                        childImageSharp{
                            gatsbyImageData(
                                placeholder: BLURRED
                                formats: [AUTO, WEBP]
                            )
                        }
                    }
                }
                Description
                employees_category {
                  Title
                }
              }
            }

        }
          

        allFile {
            edges {
            node {
                publicURL
                id
                name
                childImageSharp {
                fluid(quality: 90, maxWidth: 1900) {
                    ...GatsbyImageSharpFluid_withWebp
                }
                gatsbyImageData(
                    placeholder: BLURRED
                    formats: [AUTO, WEBP]
                )
                }
            }
            }
        }
      }
    `)

    function getImgUrl(localFile___NODE) {

        var imgUrl = { imgName: null, basicUrl: null, fluid: null, gatsbyImageData: null }

        data.allFile.edges.map((item, index) => {

            if (item.node.id === localFile___NODE) {
                // if(item.node.publicURL.endsWith(".svg")){
                //   imgUrl.basicUrl = item.node.publicURL
                // } else {
                //   imgUrl.fluid = item.node.childImageSharp.fluid
                //   imgUrl.basicUrl = item.node.publicURL
                // }
                imgUrl.imgName = item.node.name ? item.node.name : null
                imgUrl.basicUrl = item.node.publicURL ? item.node.publicURL : null
                imgUrl.fluid = item.node.childImageSharp ? item.node.childImageSharp.fluid : null
                imgUrl.gatsbyImageData = item.node.childImageSharp ? item.node.childImageSharp.gatsbyImageData : null

                // imgUrl = item.node.publicURL
            }
            return ("")

        })
        return (imgUrl)
    }

    const [employees, setEmployeeCat] = useState(data.allStrapiEmployees.edges);
    var arr = [];

    const dataCat = e => {

        if (e.target.attributes[2].textContent != "all") {
            arr = data.allStrapiEmployees.edges.filter((item) => item.node.employees_category.Title === e.target.attributes[2].textContent)
            setEmployeeCat(arr);
            buttonRef.current.style.color = "rgb(68,68,68)";
            buttonRef.current.style.backgroundColor = "rgba(0,0,0,0.05)";

        }

        else {
            setEmployeeCat(data.allStrapiEmployees.edges);
            buttonRef.current.style.color = "rgb(68,68,68)";
            buttonRef.current.style.backgroundColor = "rgba(0,0,0,0.05)";

        }


    };

    const buttonRef = useRef(null);

    useEffect(() => {

        buttonRef.current.click();
        buttonRef.current.style.color = "white";
        buttonRef.current.style.backgroundColor = "rgb(0,156,210)";

    }, []);








    return (
        <Layout SEOTitle={data.strapiMeetTheTeam.Seo.metaTitle} SEODescription={data.strapiMeetTheTeam.Seo.metaDescription} footerDisclaimer={data.strapiMeetTheTeam.FooterDisclaimer}>

            {data.strapiMeetTheTeam?.PageComponents?.map((item, index) => {
                switch (item.strapi_component) {

                    case "page-components.header":
                        return (
                            <>
                                {/* <RichSnippet type={"Organization"} data={richSnippetData} /> */}
                                <Header key={index}
                                    Title={item.Title}
                                    Subtitle={item.Subtitle}
                                    FirstCTALabel={item.FirstCTALabel}
                                    FirstCTAURL={item.FirstCTAURL}
                                    Background={item.Background !== null ? getImgUrl(item.Background.localFile___NODE) : ""}
                                    Image={item.Image !== null ? getImgUrl(item.Image.localFile___NODE) : null}
                                    BackgroundColor={item.BackgroundColorHeader}
                                >
                                </Header>
                            </>

                        )

                    default:
                        return ("error")
                }
            })}

            <div className="categories">
                <div className="btn">
                    <button type="button" onClick={(e) => dataCat(e)} className="category-btn" data-category="all">All</button>
                    {
                        data.allStrapiEmployeesCategories.edges.map((item, index) => {
                            return (
                                <button key={index}
                                    type="button"
                                    onClick={(e) => dataCat(e)}
                                    className="category-btn"
                                    data-category={item.node.Title}
                                    ref={item.node.Title === "Directors" ? buttonRef : null}
                                >
                                    {item.node.Title}
                                </button>
                            )
                        }
                        )
                    }
                </div>
            </div>

            <div className="employees">
                <div className="uk-child-width-1-3@l uk-child-width-1-3@m uk-child-width-1-2@s  uk-grid-match uk-grid uk-text-center" uk-grid="true" id="margin-cardpage">
                    {
                        employees ? employees.map((item, index) => {

                            return (
                                <MttCard
                                    key={index}
                                    Image={item.node.Image}
                                    Alt={item.node.Alt}
                                    Author={item.node.Author}
                                    Gender={item.node.Gender}
                                    Description={item.node.Description}
                                />
                            )

                        })
                            : ""
                    }
                </div>
            </div>
        </Layout >
    )


}

export default MeetTheTeam